import styled from "styled-components";

export default styled.div`
  cursor: pointer;
  display: flex;

  & span.ant-typography {
    margin-left: var(--s-2);
    color: var(--color-primary);
    font-weight: 500;
  }
`;
